import React, {useState, useEffect, useRef} from "react";
import "./Events.css";
import { Glyphicon, Button, PageHeader, Tooltip, OverlayTrigger } from "react-bootstrap";
import { View } from "react-native";
import { getCap, storeCap, getUser, getUserInformation, renderAccessDenied, pasteAsPlainText, disableNewLines, reformatDate } from "../utils/Common";
import { loadCap, getEvent, getEventVideo, putEventName, deleteEventVideo, recordUserAction, getCapture, patchUserNotification } from "../utils/bedrockAPI";
import { Player, BigPlayButton, ControlBar, VolumeMenuButton } from "video-react";
import "video-react/dist/video-react.css";
import ContentEditable from "react-contenteditable";

export default function Replay(props) {
    const [cap, setCap] = useState([]);
    const [event, setEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [renderError, setRendorError] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    var eventName = useRef("");
    const nameElement = useRef(null);
    const [buttonSize, setButtonSize] = useState("large");
    const [capture,setCapture] = useState(null);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[]);

    useEffect(() => {});

    useEffect(() => {
        let isMounted = true;
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    if(isMounted)
                        storeCap(tempCap);
                }
                if(isMounted) {
                    setCap(tempCap);
                    setIsLoading(false);
                }
            } catch(e) {
                console.log(e);
                // setRenderError(true);
            }
        }
        if ( props.match.params.capid ) {
            accessCap();
            recordUserAction(getUser().userId,"replay",props.match.params.capid,{'eventId':props.match.params.eventid,'userData':getUserInformation()});
        }
        return () => { isMounted = false; };
    },[props.match.params.capid]);

    useEffect(() => {
        let isMounted = true;
        async function loadEvent() {
            try {
                setIsLoading(true);
                const tempEvent = await getEvent(props.match.params.eventid);
                if(isMounted) {
                    setEvent(tempEvent);
                    eventName.current = tempEvent.name;
                    setIsLoading(false);
                }
            } catch(e) {
                if(isMounted)
                    setRendorError(true);
            }
        }
        if(props.match.params.eventid) {
            loadEvent();
        }
        return () => { isMounted = false; };
    // },[props.match.params.eventid]);
    }, [props.match.params.eventid]);

    useEffect(() => {
        if(props.match.params.notificationid) {
            const update = {clickDate:new Date()};
            patchUserNotification(props.match.params.notificationid,update);
        }
    },[props.match.params.notificationid])

    useEffect(() => {
        let isMounted = true;

        async function loadCapture() {
            try {
                const tempCapture = await getCapture(event.captureId);
                if(isMounted)
                    setCapture(tempCapture);
            } catch(e) {
                // pass
            }
        }
        async function loadVideoUrl() {
            try {
                setIsLoading(true);
                const tempVideoUrl = await getEventVideo(event.monitorId,event.eventId);
                if(isMounted) {
                    setVideoUrl(tempVideoUrl);
                    setIsLoading(false);
                }
            } catch (e) {
                if(isMounted)
                    setRendorError(true);   
            }
        }
        if(event) {
            loadVideoUrl();
        }
        if(event && event.captureId) {
            loadCapture();
        }
        return () => { isMounted = false; };
    },[event])

    var highlightAll = function(e) {
        var range = document.createRange();
        range.selectNodeContents(nameElement.current.getEl());
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    function renderReplay() {
        var handleNameChange = async function(e) {
            const name = e.target.value.replace(/(<([^>]+)>)/ig,"");
            eventName.current = name;
            // putEventName(event.eventId, name)
        };

        var saveToServer = function(e) {
            putEventName(event.eventId, eventName.current);
        }

        return (
            <View style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <PageHeader style={{marginTop:"-10px"}}>
                    <View style={{flex:1, flexDirection:"row",justifyContent:'space-between'}}>
                        {/* {console.log(getUser().caps[props.match.params.capid])} */}
                        <ContentEditable
                            html={eventName.current}
                            // className="ContentEditable-header"
                            disabled={isLoading || (props.match.params.capid && getUser().caps[props.match.params.capid].accessType!=="OWNER")}
                            onPaste={pasteAsPlainText}
                            onKeyPress={disableNewLines}
                            onFocus={highlightAll}
                            onChange={handleNameChange}
                            onBlur={saveToServer}
                            ref={nameElement}
                        />
                        {buttonSize==="large" && (
                            <div style={{fontSize:"24px"}}>
                                {reformatDate(event.startDate)}
                            </div>
                        )}
                    </View>
                </PageHeader>
                <View style={{flex: 1, flexDirection: "column", justifyContent: "center"}}>
                    {buttonSize==="small" && (
                        <div style={{fontSize:"24px",paddingBottom:"15px"}}>
                            {reformatDate(event.startDate)}
                        </div>
                    )}
                    <View style={{flex:1, flexDirection:"row", justifyContent:"center"}}>
                        <View style={{width:"100%", maxWidth: "704px", height: undefined, aspectRatio:1.4667}}>
                            <Player>
                                <source src={videoUrl.url} />
                                <BigPlayButton position="center" />
                                <ControlBar>
                                    <VolumeMenuButton disabled />
                                </ControlBar>
                            </Player>
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    function renderHeader() {

        async function removeEvent() {
            setIsLoading(true);
            if(window.confirm(`Are you sure you wish to delete ${event.name}?`)) {
                await deleteEventVideo(event.eventId);
                props.history.push(`/caps/${cap.capId}/events`)
            }
            setIsLoading(false);
        }

        const downloadTooltip = (
            <Tooltip id="download" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Download
            </Tooltip>
        );
        const editNameTooltip = (
            <Tooltip id="editName" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Edit Event Name
            </Tooltip>
        );
        const snapshotTooltip = (
            <Tooltip id="snapshot" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                High Res Snapshot
            </Tooltip>
        );
        const deleteTooltip = (
            <Tooltip id="delete" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Delete Event
            </Tooltip>
        )
        const fourCamTooltip = (
            <Tooltip id="fourCam" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                View 360 Event
            </Tooltip>
        )

        return (
            <div>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between',padding:10}}>
                    <Button
                        bsStyle="primary"
                        bsSize={buttonSize}
                        // disabled={isLoading}
                        onClick={() => props.history.push(`/caps/${cap.capId}/events`)}
                    >
                        Event Dashboard
                    </Button>
                    <View style={{flex: 1, flexDirection: 'row-reverse'}}>
                        {/* <Button
                            bsStyle="info"
                            bsSize="large"
                            // disabled={isLoading}
                            onClick={() => window.location.reload(false)}
                        >
                            <Glyphicon glyph="refresh"></Glyphicon>
                        </Button> */}
                        <View style={{paddingRight: "5px"}}>
                            <OverlayTrigger placement="bottom" overlay={downloadTooltip}>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    href={videoUrl.url}
                                >
                                    <Glyphicon glyph="download-alt" />
                                </Button>
                            </OverlayTrigger>
                        </View>
                        {props.match.params.capid && getUser().caps[props.match.params.capid].accessType==="OWNER" && (
                            <React.Fragment>
                                <View style={{paddingRight: "5px"}}>
                                    <OverlayTrigger placement="bottom" overlay={deleteTooltip}>
                                        <Button
                                            bsStyle="danger"
                                            bsSize={buttonSize}
                                            onClick={() => removeEvent()}
                                        >
                                            <Glyphicon glyph="remove" />
                                        </Button>
                                    </OverlayTrigger>
                                </View>
                                <View style={{paddingRight: "5px"}}>
                                    <OverlayTrigger placement="bottom" overlay={editNameTooltip}>
                                        <Button
                                            bsStyle="success"
                                            bsSize={buttonSize}
                                            onClick={() => highlightAll()}
                                        >
                                            <Glyphicon glyph="edit" />
                                        </Button>
                                    </OverlayTrigger>
                                </View>
                                {capture && capture.events && capture.events.length > 1 && (
                                    <View style={{paddingRight:"5px"}}>
                                        <OverlayTrigger placement="bottom" overlay={fourCamTooltip}>
                                            <Button
                                                variant="default"
                                                // style={{color:"white",background:"gray"}}
                                                bsStyle="primary"
                                                bsSize={buttonSize}
                                                onClick={() => props.history.push(`/caps/${cap.capId}/360events/${event.captureId}/${event.eventId}`)}
                                            >
                                                <Glyphicon glyph="camera" />
                                            </Button>
                                        </OverlayTrigger>
                                    </View>
                                )}
                                {event && event.imageId && (
                                    <View style={{paddingRight: "5px"}}>
                                        <OverlayTrigger placement="bottom" overlay={snapshotTooltip}>
                                            <Button
                                                bsStyle="warning"
                                                bsSize={buttonSize}
                                                onClick={() => props.history.push(`/caps/${cap.capId}/events/${props.match.params.eventid}/snapshot`)}
                                            >
                                                <Glyphicon glyph="picture" />
                                            </Button>
                                        </OverlayTrigger>
                                    </View>
                                )}
                            </React.Fragment>
                        )}
                    </View>
                </View>
                {event && videoUrl && renderReplay()}
            </div>
        )
    }

    function renderNoEvent() {
        return (
            <div style={{paddingTop: "100px", textAlign: "center"}}>
                <h3>Unable to locate event, please refresh</h3>
            </div>
        )
    }

    return (
        <div className="Replay">
            {renderError ? renderNoEvent() : !(props.match.params.capid || getUser().role==="ADMIN") ? renderAccessDenied() : renderHeader()}
        </div>
    )
}