import React, { useState, useEffect } from "react";
import "./Home.css";
import { 
  getToken, 
  getUser, 
  // salesClick, 
  getNeighborhoodCollapsed, 
  storeNeighborhoodCollapsed, 
  getUserInformation,
  setDefaultPageNumber, 
  setDefaultSortParameter, 
  setDefaultDaylightParameter, 
  setDefaultCameraDirections,
  removeDefaultEventParameters
} from "../utils/Common";
import { PageHeader, ListGroup, ListGroupItem, Button, Glyphicon, Tooltip, OverlayTrigger } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { View } from "react-native";
import ContentEditable from "react-contenteditable";
// import Select from "react-select";
import { 
  getCaps, 
  deleteCapAndAssociated, 
  makeNewCap, 
  recordUserAction, 
  companyCamAccessToken,
  createCCIntegration,
  patchUserTZ
} from "../utils/bedrockAPI";
import Collapsible from 'react-collapsible';
import SharingModal from "../components/SharingModal";
import Nudge from "../containers/Nudge.js";

export default function Dashboard(props) {
  const [caps, setCaps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [localToken, setLocalToken] = useState("");
  const [newName, setNewName] = useState("New Cap");
  const [newPort, setNewPort] = useState("Port");
  const [sharableCap, setsharableCap] = useState(null);
  const [showSharingWindow, setShowSharingWindow] = useState(false);
  // const [sharedEmail, setSharedEmail] = useState("");
  const [neighborhoodCollapsed, setNeighborhoodCollapsed] = useState({});
  const [neighborhoods, setNeighborhoods] = useState(null);
  const [buttonSize, setButtonSize] = useState("large");
  // const [sharedType,setSharedType] = useState("DEMO");
  // const [emailFocused, setEmailFocused] = useState(false);
  // const [motionCheckBox, setMotionCheckBox] = useState(false);
  // const [editingCheckBox, setEditingCheckBox] = useState(false);
  // const [powerCheckBox, setPowerCheckBox] = useState(false);
  const [buildCaps, setBuildCaps] = useState(false);
  const [companyCamCode, setCompanyCamCode] = useState(null);
  const [CCAccessToken, setCCAccessToken] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if(isMounted) setLocalToken(getToken());
    if(window.innerWidth<767 && isMounted) {
      setButtonSize("small");
    }
    if(props.errorStatus!=='nudge-message' && isMounted) {
      props.setShowError(false);
    }
    if(isMounted) {
      recordUserAction(getUser().userId,"dashboard",null,{'userData':getUserInformation()});
      removeDefaultEventParameters();
    }

    let user = getUser();
    if(user && (!user.firstName || user.firstName==="")) {
      props.history.push('/account/info');
    }
    if(user && (user.role==='PAYMENTS')) {
      props.history.push('/reserve');
    }
    // props.setErrorMessage("We're currently experiencing an issue with our image API, you won't be able to pull live images.  We're working on a fix and will update you shortly.");
    // props.setErrorStatus("error-message");
    // props.setShowError(true);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get("code");
    if(code && isMounted) {
      setCompanyCamCode(code);
    }

    const timezoneOffset = (new Date()).getTimezoneOffset();
    if(timezoneOffset) {
      const u = getUser();
      if(u && u.tzOffset && u.tzOffset !== timezoneOffset) {
        patchUserTZ(u.userId,timezoneOffset);
        console.log("Updating User TZ",timezoneOffset);
      }
    }

    return () => { isMounted = false; };
  }, []);

  useEffect(() => {
    let isMounted = true;
    async function loadCaps() {
      if(getToken()!=null) {
        const tempCaps = await getCaps();
        if(isMounted) {
          setCaps(tempCaps);
        }
        let tempNeighborhoods = {};
        // const tempNeighborhoods = await loadNeighborhoods();
        let nc = getNeighborhoodCollapsed();
        if(nc==null) nc = {};
        for(var i=0; i<tempCaps.length; i++) {
          if(!(tempCaps[i].neighborhoodid in nc)) {
            if(tempCaps.length<=8) { // Leave collaspibles open if they have less than 9 caps
              nc[tempCaps[i].neighborhoodid] = true;
            } else {
              nc[tempCaps[i].neighborhood] = false;
            }
          }
          if(tempCaps[i].neighborhoodid == null) {
            if('none' in tempNeighborhoods) {
              tempNeighborhoods['none'].push(tempCaps[i]);
            } else {
              tempNeighborhoods['none'] = [tempCaps[i]];
            }
          }
          else if(tempCaps[i].neighborhoodid in tempNeighborhoods) {
            tempNeighborhoods[tempCaps[i].neighborhoodid].push(tempCaps[i]);
          } else {
            tempNeighborhoods[tempCaps[i].neighborhoodid] = [tempCaps[i]];
          }
        }
        // Sort caps without neighborhoods
        if('none' in tempNeighborhoods) {
          tempNeighborhoods['none'].sort((a,b) => (a.name > b.name ) ? 1 : -1);
        }
        // Sort neighborhoods
        if(isMounted) {
          setNeighborhoodCollapsed(nc);
          setNeighborhoods(tempNeighborhoods);
          
          setIsLoading(false);
        }
      }
    }
    loadCaps();
    return () => { isMounted = false; };
  }, [localToken,isLoading]);

  // If you go all the way back to dashboard (or if you relogin) you want the sorting to reset
  useEffect(() => {
    let isMounted = true;
    if(neighborhoods && isMounted) {
      setDefaultCameraDirections({});
      setDefaultDaylightParameter({});
      setDefaultPageNumber({});
      setDefaultSortParameter({});
    }
    return () => { isMounted = false; };
  },[neighborhoods]);

  useEffect(() => {
    let isMounted = true;
    async function getAccessToken() {
      let at = await companyCamAccessToken(companyCamCode);
      console.log(at);
      setCCAccessToken(at);
      createCCIntegration(getUser().userId,at);
    }
    if(companyCamCode) {
      if(CCAccessToken==null && isMounted) {
        getAccessToken();
      }
    }
    return () => { isMounted = false; };
  },[companyCamCode])

  const tooltip = (
    <Tooltip id="share" style={{fontSize: "24px"}}>
      Share
    </Tooltip>
  );

  // async function deleteCap(capId) {
  //   setIsLoading(true);
  //   const response = await deleteCapAndAssociated(capId);
  //   console.log(response);
  //   // TODO: Notify of successful delete
  //   setIsLoading(false);
  // };

  function renderSubCaps(neighborhood) {
    return neighborhoods && neighborhoods[neighborhood] && (neighborhoods[neighborhood].sort((a,b) => (a.name > b.name ) ? 1 : -1)).map(function(cap,i) {
        // console.log(cap.status);
        if( getUser().role === "ADMIN" && cap.status === "BUILD" && !buildCaps ) {
          // There's a button at the top for ADMINs that can show/hide caps with BUILD status
          return null;
        }
        if(["DECOMISSIONED","DEAD"].indexOf(cap.status) >= 0) {
          return null;  
        } else {
          return (
            <View key={cap.capid} style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
              <LinkContainer style={{width:'100%'}} to={`/caps/${cap.capid}`}>
                  <ListGroupItem header={cap.name}>
                    {"Created: "+ new Date(cap.adddate).toLocaleString()}
                    {getUser().role==="ADMIN" && (" | Port: "+cap.port)}
                  </ListGroupItem>
              </LinkContainer>
              {getUser().caps[cap.capid].accessType==="OWNER" && (
                // <OverlayTrigger placement="left" overlay={tooltip}>
                  <Button 
                    bsStyle="info"
                    bsSize={buttonSize}
                    disabled={isLoading}
                    onClick={() => {setShowSharingWindow(true); setsharableCap(cap)}}
                  >
                      {/* <Glyphicon glyph="share"></Glyphicon> */}
                      <b>{buttonSize==="large" ? "Share this CAP" : "Share"}</b>
                  </Button>
                // </OverlayTrigger>
              )}
              {/* {getUser().role==="ADMIN" && (
                <Button
                  bsStyle="danger"
                  bsSize="small"
                  disabled={isLoading}
                  onClick={() => {if (window.confirm(`Are you sure you wish to delete ${cap.name}?`)) deleteCap(cap.capid)}}
                >
                  <Glyphicon glyph="remove"></Glyphicon>
                </Button>
              )} */}
            </View>
          )
      }}
    );
  }

  function renderUnderConstruction(neighborhood) {
    let cap = neighborhoods[neighborhood][0];
    return (
      <View key={cap.capid} style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
        {/* <LinkContainer  to={`/caps/${cap.capid}`}> */}
          <ListGroupItem style={{width:'100%'}} header="Under Construction">
            {/* {"Created: "+ new Date(cap.adddate).toLocaleString()}
            {getUser().role==="ADMIN" && (" | Port: "+cap.port)} */}
          </ListGroupItem>
        {/* </LinkContainer> */}
        <OverlayTrigger placement="left" overlay={tooltip}>
          <Button 
            bsStyle="info"
            bsSize="large"
            disabled={isLoading}
            onClick={() => {setShowSharingWindow(true); setsharableCap(cap)}}
          >
              <Glyphicon glyph="share"></Glyphicon>
          </Button>
        </OverlayTrigger>
      </View>
    );
  }
  
  function renderCapsList2() {
    async function expandContract(e,n) {
      if(e.currentTarget.className===e.target.className || 
        e.currentTarget.className===e.target.parentElement.className || 
        e.target.className.includes("glyphicon")) {
        let newNeighborhood = JSON.parse(JSON.stringify(neighborhoodCollapsed));
        newNeighborhood[n] = !newNeighborhood[n]
        setNeighborhoodCollapsed(newNeighborhood);
        storeNeighborhoodCollapsed(newNeighborhood);
      }
    }
    let sorted = [];
    for (var n in neighborhoods) {
      sorted.push([n,neighborhoods[n]]);
    }
    sorted.sort((a,b) => (a[1][0].neighborhood > b[1][0].neighborhood) ? 1 : -1);
    
    // console.log(neighborhoods);
    // TODO: Figure out how not to load decommissioned
    // { neighborhoodCollapsed && Object.keys(neighborhoodCollapsed).length > 0 && (
    return ( 
    <View>
      {sorted.map(function(neighborhood,i) {
      if(neighborhood[0]==='none') {
        return;
      } else {
        var decommissioned = true;
        for(var j=0; j< sorted[i][1].length; j++) {
          if(["DECOMISSIONED","DEAD"].indexOf(neighborhood[1][j].status) < 0) {
            decommissioned = false;
          }
          if(getUser().role==="ADMIN" && !buildCaps && neighborhood[1][j].status === "BUILD") {
            // ADMINs can toggle BUILD status caps 
            decommissioned = false;
          }
        }
        if(!decommissioned) {
          return (
            <View style={{cursor: "pointer"}} key={neighborhood[0]} onClick={(e) => {expandContract(e,neighborhood[0])}}>
              <ListGroupItem key={neighborhood[0]} style={{backgroundColor:"#F6F6F6"}} 
                // onMouseEnter={(e) => e.target.style.background="#FAFAFA"}
                // onMouseLeave={(e) => e.target.style.background="#FFF"}
                // onClick={() => {neighborhoodCollapsed[caps[0].capid]=!neighborhoodCollapsed[caps[0].capid]; setNeighborhoodCollapsed(neighborhoodCollapsed); console.log(neighborhoodCollapsed);}}
              >
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                  <div className="Collapsible-header">
                    {neighborhood[1][0].neighborhood}
                  </div>
                  <div style={{padding:"15px"}}>
                    <Glyphicon glyph={neighborhoodCollapsed[neighborhood[0]] ? "chevron-up" : "chevron-down"}></Glyphicon>
                  </div>
                </View>
                <Collapsible transitionTime={150} open={neighborhoodCollapsed[neighborhood[0]]}>
                  <ListGroup>
                    {neighborhood[1][0].name == null ? renderUnderConstruction(neighborhood[0]) : renderSubCaps(neighborhood[0])}
                  </ListGroup>
                </Collapsible>
              </ListGroupItem>
            </View>
          )
        } else {
          return null;
        }
      }})}
      {renderSubCaps('none')}
    </View>)
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Bedrock Wireless</h1>
        <p>Your gateway to virtual construction management</p>
      </div>
    );
  }

  function renderCaps() {
    async function createNew() {
      setIsLoading(true);
      makeNewCap(newName,newPort);
      // console.log(newCap);
      // TODO: show success of new cap
      props.setShowError(true);
      props.setErrorMessage("New CAP successfully created");
      props.setErrorStatus("success-message");
      setIsLoading(false);
    }
    async function createNew2Cam() {
      setIsLoading(true);
      makeNewCap(newName,newPort,true);
      props.setShowError(true);
      props.setErrorMessage("New 2 cam successfully created");
      props.setErrorStatus("success-message");
      setIsLoading(false);
    }
    var handleNameChange = function(event) {
      setNewName(event.target.value.replace(/(<([^>]+)>)/ig,""));
    };

    var handlePortChange = function(event) {
      setNewPort(event.target.value)
    };

    var pastAsPlainText = function(event) {
      event.preventDefault();

      const text = event.clipboardData.getData('text/plain');
      document.execCommand('insertHTML',false,text);
    };

    var disableNewLines = function(event) {
      const keyCode = event.keyCode || event.which;

      if(keyCode === 13) {
        event.returnValue = false;
        if (event.preventDefault) event.preventDefault()
      }
    };

    var highlightAll = function(event) {
      setTimeout(() => {
        document.execCommand('selectAll', false, null);
      }, 0);
    } 

    return (
      <div className="caps">
        <PageHeader style={{marginTop:"0px"}}>
          <View style={{flex:1, flexDirection: "row", justifyContent: "space-around"}}>
            <div style={{paddingTop:"15px"}}>Dashboard</div>
            <View style={{flex:1, flexDirection:"row",justifyContent:"flex-end"}}>
              <Button
                bsStyle="info"
                bsSize={buttonSize}
                onClick={() => props.history.push('/signupnewcap')}
                >
                  <Glyphicon glyph="plus" />&nbsp;&nbsp;<b>{buttonSize==="small" ? "Activate a New CAP" : "Activate a New CAP"}</b>
              </Button>
              {/* {
                getUser().role === "ADMIN" && buttonSize==="large" && (
                  <View style={{paddingLeft:"5px"}}>
                    <Button
                      bsStyle={buildCaps ? "warning" : "danger"}
                      bsSize={buttonSize}
                      onClick={() => setBuildCaps(!buildCaps)}
                    >
                      {buildCaps ? "Showing BUILD" : "Not Showing BUILD"}
                    </Button>
                  </View>
                )
              } */}
            </View>
          </View>
        </PageHeader>
        <ListGroup>
          <LinkContainer key="Title" to={"/dashboard"}>
            <ListGroupItem key="Title">
              <h2>Construction Access Points (Caps)</h2>
              {/* <h4>(CAPs)</h4> */}
            </ListGroupItem>
          </LinkContainer>
          {!isLoading && renderCapsList2()}
          {!isLoading && Object.keys(neighborhoods).length===0 ? (
            <View style={{flex:1, flexDirection: "row", justifyContent:"center", paddingTop: "50px"}}>
              <h4>
                Sign up for a CAP or ask our team how to have a demo CAP shared with you
              </h4>
            </View>
            ): null}
          {getUser().role === "ADMIN" && (
            <View key="new" style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
              <ListGroupItem style={{flex: 1, flexDirection: 'column', background: "#EEE"}}>
                <ContentEditable
                  html={newName}
                  className="ContentEditable-header"
                  disabled={isLoading}
                  onPaste={pastAsPlainText}
                  onKeyPress={disableNewLines}
                  onFocus={highlightAll}
                  onChange={handleNameChange}
                />
                <ContentEditable
                  html={newPort}
                  className="ContentEditable-text"
                  disabled={isLoading}
                  onPaste={pastAsPlainText}
                  onKeyPress={disableNewLines}
                  onFocus={highlightAll}
                  onChange={handlePortChange}
                />
              </ListGroupItem>
              <Button
                bsStyle="warning"
                bsSize="small"
                disabled={isLoading}
                onClick={createNew2Cam}
              >
                <Glyphicon glyph="plus-sign" />
              </Button>
              <Button
                bsStyle="success"
                bsSize="small"
                disabled={isLoading}
                onClick={createNew}
              >
                <Glyphicon glyph="plus"></Glyphicon>
              </Button>
            </View>
          )}
          {/* {!isLoading && neighborhoods && renderCapsList2()} */}
        </ListGroup>
        
      </div>
    );
  }

  return (
    <div className="Home">
      {getToken()!=null ? renderCaps() : renderLander()}
      {caps && (
        <SharingModal 
          cap={sharableCap}
          show={showSharingWindow}
          setErrorStatus={props.setErrorStatus}
          setErrorMessage={props.setErrorMessage}
          setShowError={props.setShowError}
          setShowSharingWindow={setShowSharingWindow}
        />
      )}
      {props && props.showError !== null && getUser() && ( // && getUser().role==='ADMIN' // TODO 
        <Nudge 
          setErrorStatus={props.setErrorStatus} 
          setErrorMessage={props.setErrorMessage}
          setShowError={props.setShowError}
          showError={props.showError}
          history={props.history}
        />
      )}
    </div>
  )
}