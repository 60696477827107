import axios from "axios";
import { getToken, getUser } from "./Common";

const client_id = "dqs_YpZSj0treWlR6AB4GrNh45vVwn3L7JA3HvCuncs";
const redirect_uri=encodeURIComponent("https://data.bedrockwireless.com/dashboard");
const secret_id = "pcFZWLwU2S16wdRlOMO9l8oAMOhd_OIbeGAI4dgMzD8";

export const authorizeCompanyCam = () => {
    // const config = {
    //     headers: {
    //         accept: 'application/json'
    //     }
    // };
    const url = `https://app.companycam.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=read+write+destroy`;
    // return new Promise((resolve,reject) => {
    //     axios.get(url).then(response => {
    //         resolve(response.data);
    //     }).catch(error => {
    //         console.log(error);
    //         reject(error);
    //     });
    // });
    return url;
}

export const companyCamAccessToken = (code) => {
    let url = "https://app.companycam.com/oauth/token";
    url += `?client_id=${client_id}&client_secret=${secret_id}&code=${code}&grant_type=authorization_code&redirect_uri=${redirect_uri}`;
    return new Promise((resolve,reject) => {
        axios.post(url).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

export const getConfig = (token="") => {
    if(token==="") {
        token = getToken();
    }
    const config = {
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return config;
};

export const getPromiser = (url) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(url,config).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

export const delPromiser = (url) => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.delete(
            url,
            config
          ).then(response => {
            resolve(response);
          }).catch(error => {
            console.log(error);
            reject(error);
          });
    });
}

export const patchPromiser = (url,newObject,token="") => {
    let config;
    if(token==="") {
        config = getConfig();
    } else {
        config = getConfig(token);
    }
    return new Promise((resolve,reject) => {
        axios.patch(
            url,
            newObject,
            config
        ).then(response => {
            resolve(response);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

export const postPromiser = (url,data) => {
    const token = getToken();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return new Promise ((resolve, reject) => {
        axios.post(
            url,
            data,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

export const getUserMe = () => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/users/me`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const putUser = (userId,changes,token="") => {
    if(token==="") {
        token = getToken();
    }
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    for(var item in changes) {
        if(changes[item]==="") delete(changes[item]);
    }
    return new Promise((resolve, reject) => {
        axios.put(
            `${process.env.REACT_APP_API_URL}/users/${userId}`,
            changes,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const changePassword = (userId,oldPassword,newPassword) => {
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        }
    }
    const data = {
        'userId': userId,
        'password': oldPassword,
        'newPassword': newPassword
    }
    return new Promise((resolve,reject) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/users/changePassword`,
            data,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getCaps = () => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/users/caps`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const loadNeighborhoods = () => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/users/neighborhoods`,
            config
        ).then(response => {
            resolve(response.data)
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
};

export const makeNewFile = (name) => {
    return new Promise((resolve, reject) => {
        const f = "test";
        resolve(f);
    })
}

export const loadCap = (capId) => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/caps/${capId}`,
            config
        ).then(response => {
            // edit tags
            let newCap = {}
            newCap['capId'] = response.data.capid;
            newCap['name'] = response.data.name;
            newCap['port'] = response.data.port;
            newCap['addDate'] = response.data.adddate;
            newCap['neighborhood'] = response.data.neighborhood;
            newCap['architecture'] = response.data.architecture;
            newCap['health'] = response.data.health;
            newCap['lights'] = response.data.lights;
            newCap['builderId'] = response.data.builderid;
            newCap['neighborhoodId'] = response.data.neighborhoodid;
            newCap['status'] = response.data.status;
            newCap['bandwidth'] = response.data.bandwidth;
            resolve(newCap);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const loadEmails = () => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/users/emails`,
            config
          ).then(response => {
            let emails = [];
            response.data.forEach((email,i) => {
              emails.push({value:email['userid'],label:email['email']})
            });
            resolve(emails);
          }).catch(error => {
            console.log(error);
            reject(error);
          });
    });
};

export const loadCapEmails = (capId) => {
    const config = getConfig();
    const url = `${process.env.REACT_APP_API_URL}/users/${capId}/emails`;
    return new Promise((resolve,reject) => {
        axios.get(
            url,
            config
        ).then(response => {
            let emails = [];
            response.data.forEach((email,i) => {
                emails.push({value:email['userid'],label:email['email']})
            });
            resolve(emails);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const deleteCapAndAssociated = (capId) => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.delete(
            `${process.env.REACT_APP_API_URL}/caps/${capId}`,
            config
          ).then(response => {
            // console.log(response.data);
            resolve(response);
          }).catch(error => {
            console.log(error);
            reject(error);
          });
    });
};

export const makeNewCap = (name, port, twoCam=false) => {
    const token = getToken();
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
    }
    if(port==="Port") port = "0";
    const data = {
        "name": name,
        "port": parseInt(port,10),
        "architecture": twoCam ? 'ODROID JETSON BROMINE 2CAM' : 'ODROID JETSON BROMINE'
    }
    return new Promise((resolve, reject) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/caps`,
            data,
            config
          ).then(response => {
            // console.log(response.data);
            resolve(response);
          }).catch(error => {
            if(error.response.status === 422) {
              alert("Please use a real port number");
            }
            console.log(error);
            reject(error);
          });
    });
};

export const shareCapWithUser = (cap,user,accessType) => {
    const token = getToken();
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
    }
    const data = {
        'userId': user,
        'capId': cap,
        'accessType': accessType
    }
    return new Promise ((resolve, reject) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/caps/share`,
            data,
            config
        ).then(response => {
            // console.log(response);
            resolve(response);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getLatestStoredImages = (cap) => {
    const config = getConfig();
    return new Promise ((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/caps/${cap}/latestCaptures`,
            config
        ).then(response => {
            var imagesObject = {}
            for(var i=0; i<response.data.length; i++) {
                imagesObject[response.data[i].monitorId] = {
                    url: response.data[i].url,
                    captureDate: response.data[i].captureDate,
                    id: response.data[i].id,
                };
            }
            resolve(imagesObject);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getLatestStoredImage = (cap,monitor) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        const url = `${process.env.REACT_APP_API_URL}/caps/${cap}/latest/${monitor}`;
        axios.get(
            url,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getMonitors = (cap) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${cap}/monitors`
    var response = getPromiser(url)
    response['capId'] = cap;
    return response;
};

export const loadFiles = (capId) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/caps/${capId}/files`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(e => {
            console.log(e);
            reject(e);
        });
    });
}

export const getEvents = (monitor,page=0,orderBy="startDate DESC",daylight=null,real=true) => {
    var config = getConfig();
    const offset = (25*(page)).toString();
    var url = `${process.env.REACT_APP_API_URL}/monitors/${monitor}/events?filter[where][status]=IN_S3&filter[where]`
    if(real) {
        url+=`&filter[real]=true`
    }
    if(daylight!==null) {
        if(daylight==='none') {
            url+=`&filter[where][id]=-1`
        } else {
            url+= `&filter[where][daylight]=${daylight}`
        }
    }
    url += `&filter[offset]=${offset}&filter[order]=${orderBy}&filter[limit]=25`;
    console.log(url)
    return new Promise((resolve, reject) => {
        axios.get(
            url,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getCapEvents = (capId,page=0,orderBy="startDate DESC",daylight=null,real=true,monitors=[],startDate=null,endDate=null,cars=null,persons=null) => {
    var limit = 20;
    const offset = (limit*(page)).toString();
    var url = `${process.env.REACT_APP_API_URL}/events/cap/${capId}`;
    url += `?filter[where][status]=IN_S3`;
    // url += `&filter[where][analysis.real]=${real}`;
    url+=`&filter[where][url][neq]=null`;
    if(real) {
        url+=`&filter[where][analysis.real]=true`;
    }
    if(monitors==null) {
        limit = 0;
    } else if(monitors.length>1) {
        for(var i=0; i<monitors.length; i++) {
            url+= `&filter[where][monitorId][inq]=${monitors[i]}`;
        }
    } else if(monitors.length===1) {
        url+=`&filter[where][monitorId]=${monitors[0]}`;
    }
    if(daylight!=null) {
        if(daylight==='none') {
            limit = 0;
        } else {
            url += `&filter[where][daylight]=${daylight}`;
        }
    }
    if(startDate!=null) {
        url+=`&filter[where][startDate][gt]=${startDate}`;
    }
    if(endDate!=null) {
        url+=`&filter[where][endDate][lt]=${endDate}`;
    }
    if(cars && !persons) {
        url+='&filter[where][and][0][analysis.yolo][like]=%25"class"%25';
        url+='&filter[where][and][1][analysis.yolo][nlike]=%25"person"%25';
    }
    else if(persons && !cars) {
        url+='&filter[where][analysis.yolo][like]=%25"class":"person"%25';
    }
    else if(!persons && !cars) {
        limit = 0;
    }
    if(limit!==0) {
        url += `&filter[offset]=${offset}&filter[order]=${orderBy}&filter[limit]=${limit}`;
        // console.log(url);
        return getPromiser(url);
    } else {
        return [];
    }
}

export const getCaptures = (capId,page=0,orderBy="capture_date DESC",daylight=null) => {
    const config = getConfig();
    const offset = (25*(page)).toString();
    var url = `${process.env.REACT_APP_API_URL}/captures/${capId}/events?`;
    if(daylight!=null) {
        if(daylight==='none') {
            url+=`filter[where][id]=-1&`;
        } else {
            url+= `filter[where][daylight]=${daylight}&`;
        }
    }
    url+=`filter[offset]=${offset}&filter[order]=${orderBy}&filter[limit]=25`;
    // console.log(url);
    return new Promise((resolve,reject) => {
        axios.get(
            url,
            config
        ).then(response => {
            // console.log(response.data);
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getFrames = (capId,pageNumber,sortDirection="captureDate DESC",monitors=[],startDate=null,endDate=null,limit=null) => {
    if(limit===null) limit = 20;
    const offset = (limit*pageNumber);
    var url = `${process.env.REACT_APP_API_URL}/captures/${capId}/frames`;
    var data = {};
    if(monitors===null) {
        limit = 0;
    } else {
        data.monitors = monitors;
    }
    data.sortDirection = sortDirection;
    if(startDate===null)
        data.startDate = "";
    else
        data.startDate = startDate;
    if(endDate===null)
        data.endDate = "";
    else
        data.endDate = endDate;
    data.limit = limit;
    data.offset = offset;
    if(limit!==0) {
        return postPromiser(url,data);
    } else {
        return [];
    }
}

// DEPRECATED
export const getFramesDeprecated = (capId,pageNumber,sortDirection="captureDate DESC",monitors=[],startDate=null,endDate=null,limit=null) => {
    if(limit===null) limit = 20;
    const offset = (limit*(pageNumber)).toString();
    var url = `${process.env.REACT_APP_API_URL}/captures/${capId}/frames?`;
    if(monitors==null) {
        limit = 0;
    } else if(monitors.length>1) {
        for(var i=0; i<monitors.length; i++) {
            url+= `filter[where][monitorId][inq]=${monitors[i]}&`;
        }
    } else if(monitors.length===1) {
        url+=`filter[where][monitorId]=${monitors[0]}&`;
    }
    if(startDate && endDate) {
        url += `filter[where][captureDate][between][0]=${startDate}&filter[where][captureDate][between][1]=${endDate}&`;
    }
    else if(startDate!=null) {
        url+=`filter[where][captureDate][gt]=${startDate}&`;
    }
    else if(endDate!=null) {
        url+=`filter[where][captureDate][lt]=${endDate}&`;
    }
    if(limit!==0) {
        url += `filter[offset]=${offset}&filter[order]=${sortDirection}&filter[limit]=${limit}`
        // console.log(url);
        return getPromiser(url);
    } else {
        return [];
    }
};

export const getEventImage = (event) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/events/${event}/image`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getCaptureImages = (capture) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/captures/${capture}/images`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getCaptureFrameImages = (capture) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/captures/${capture}/frameImages`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getMonitorImages = (monitor,order,d=null) => {
    const config = getConfig();
    var direction = "lt";
    var date = (new Date()).toISOString();
    if(d!=null) date = d.toISOString();
    if(order==="capture_date ASC") direction = "gt"; 
    const url = `${process.env.REACT_APP_API_URL}/monitors/${monitor}/images?filter[where][captureDate][${direction}]=${date}&filter[order]=${order}&filter[limit]=2`;
    // console.log(url);
    return new Promise((resolve,reject) => {
        axios.get(
            url,
            config
        ).then(response => {
            resolve(response.data)
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

export const getEvent = (event) => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/events/${event}`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getCapture = (capture) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/captures/${capture}`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const patchZone = (zoneId,zone) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}/zones/${zoneId}`,
            zone,
            config
        ).then(response => {
            resolve(response);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const putEventName = (event,name) => {
    const config = getConfig();
    const data = {name: name};
    // console.log(name);
    return new Promise((resolve, reject) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}/events/${event}`,
            data,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(e => {
            console.log(e);
            reject(e);
        });
    });
};

export const putCaptureName = (capture,name) => {
    const config = getConfig();
    const data = {name: name};
    // console.log(name);
    return new Promise((resolve, reject) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}/captures/${capture}`,
            data,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(e => {
            console.log(e);
            reject(e);
        });
    });
};


export const getEventVideo = (monitor, event) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/monitors/${monitor}/events/${event}/video`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getCaptureVideos = (capture) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/captures/${capture}/videos`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

export const getUserData = (admin) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/userAction/accessByUserDate/${admin}`,
            config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};


//TODO: make the funcotions all look like this
export const getUserHourData = () => {
    const url = `${process.env.REACT_APP_API_URL}/userAction/accessByUserHour`;
    return getPromiser(url);
}

export const loginToCamerasThroughAPI = (capId) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}/loginToCamera`;
    return getPromiser(url);
}

export const getMonitorAlarmStatus = (capId,monitorId,cameraToken) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}/triggerStatus/${monitorId}/${cameraToken}`;
    return getPromiser(url);
}

export const postStreamControl = (capId,monitorId,cameraToken,connKey,action) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}/controlStream`;
    const packet = {monitorId:monitorId,camToken:cameraToken,connKey:connKey.toString(),action:action};
    return postPromiser(url,packet);
}

export const getAccessableCaps = () => {
    let url = `${process.env.REACT_APP_API_URL}/users/accessibleCaps?`;
    url += 'filter[where][status][inq]=ACTIVE&filter[where][status][inq]=BUILD'
    return getPromiser(url);
}

export const getUsersByBuilder = (builderId) => {
    const url = `${process.env.REACT_APP_API_URL}/users/builder/${builderId}`;
    return getPromiser(url);
}

export const getCapsByBuilder = (builderId) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/builder/${builderId}`;
    return getPromiser(url);
}

export const getUsersBySharedOnCapsByBuilder = (builderId) => {
    const url = `${process.env.REACT_APP_API_URL}/users/builder/${builderId}/shared`;
    return getPromiser(url);
}

export const getAllBuilders = () => {
    const url = `${process.env.REACT_APP_API_URL}/builders/all`;
    return getPromiser(url);
}

export const getAllNeighborhoods = () => {
    const url = `${process.env.REACT_APP_API_URL}/neighborhoods/all`;
    return getPromiser(url);
}

export const getUserActions = (userId,cutoffDate=null,limit=10) => {
    let filter = "";
    if(cutoffDate) {
        filter = `?filter[where][actionDate][gt]=${cutoffDate.toLocaleString("en-US",{timezone:"UTC"})}&filter[limit]=${limit}`;
    }
    const url = `${process.env.REACT_APP_API_URL}/userAction/user/${userId}`+filter;
    return getPromiser(url);
}

export const getUserActionCount = (userId,cutoffDate=null) => {
    let filter = "";
    if(cutoffDate) {
        filter = `?filter[where][actionDate][gt]=${cutoffDate.toLocaleString("en-US",{timezone:"UTC"})}`;
    }
    const url = `${process.env.REACT_APP_API_URL}/userAction/user/${userId}/count`+filter;
    return getPromiser(url);
}

export const getUserCapData = () => {
    const url = `${process.env.REACT_APP_API_URL}/userAction/accessByUserCap`;
    return getPromiser(url);
}

export const activateCapByKey = (capActivation) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/activate`;
    return postPromiser(url,capActivation);
}

export const createNeighborhood = (neighborhood) => {
    const url = `${process.env.REACT_APP_API_URL}/neighborhoods`;
    return postPromiser(url,{name:neighborhood});
}

export const postUserMotion = (userMotion) => {
    const url = `${process.env.REACT_APP_API_URL}/userMotion`;
    return postPromiser(url,userMotion);
}

export const patchUserMotion = (id,userMotion) => {
    const url = `${process.env.REACT_APP_API_URL}/userMotion/${id}`;
    return patchPromiser(url,userMotion);
}

export const changeCapName = (capId,newCapName) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}`;
    return patchPromiser(url,{name:newCapName});
}

export const patchCapBuilder = (capId,builderId) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}`;
    return patchPromiser(url,{builderId:builderId});
}

export const patchCapHealth = (capId,newHealth) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}`;
    return patchPromiser(url,{health:newHealth});
}

export const patchCapStatus = (capId,newStatus) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}`;
    return patchPromiser(url,{status:newStatus});
}

export const patchCapBandwidth = (capId,newBandwidth) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}`;
    return patchPromiser(url,{bandwidth:newBandwidth});
}

export const changeNeighborhoodName = (neighborhoodId, newNeighborhoodName) => {
    const url = `${process.env.REACT_APP_API_URL}/neighborhoods/${neighborhoodId}`;
    return patchPromiser(url,{name:newNeighborhoodName});
}

export const changeCapNeighborhood = (capId,neighborhoodId) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}`;
    return patchPromiser(url,{neighborhoodId:neighborhoodId});
}

export const getZones = (capId) => {
    const url = `${process.env.REACT_APP_API_URL}/zones/${capId}`;
    return getPromiser(url);
}

export const getSharingGrid = () => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/all`;
    return getPromiser(url);
}

export const getThumbUrl = (imageId) => {
    const url = `${process.env.REACT_APP_API_URL}/images/${imageId}/thumb`;
    return getPromiser(url);
}

export const getRandomImage = (labeled) => {
    const filter = labeled==null ? "ALL" : labeled ? "TRUE" : "FALSE";
    const url = `${process.env.REACT_APP_API_URL}/images/random/${filter}`;
    return getPromiser(url);
}

export const getSpecificImage = (imageId) => {
    const url = `${process.env.REACT_APP_API_URL}/images/${imageId}/url`;
    return getPromiser(url);
}

export const getUserImage = (userId) => {
    const url = `${process.env.REACT_APP_API_URL}/images/labeled/user/${userId}`;
    return getPromiser(url);
}

export const getLabelClasses = () => {
    // s?filter[where][status]=IN_S3&filter
    var url = `${process.env.REACT_APP_API_URL}/labelclass/classes`;
    url += `?filter[where][status][inq]=ALWAYS&filter[where][status][inq]=MOTION`;
    url += `&filter[order][0]=status asc&filter[order][1]=name asc`
    return getPromiser(url);
}

export const getImageLabels = (imageId) => {
    var url = `${process.env.REACT_APP_API_URL}/imagelabel/${imageId}`;
    return getPromiser(url);
}

export const deleteCapData = (capId) => {
    var url = `${process.env.REACT_APP_API_URL}/caps/${capId}/deleteData`;
    return postPromiser(url);
}

export const prepareCapForShipping = (capId,stripeId) => {
    var url = `${process.env.REACT_APP_API_URL}/caps/${capId}/ship/${stripeId}`;
    return postPromiser(url);
}

export const createBuilder = (builder) => {
    const url = `${process.env.REACT_APP_API_URL}/builders`;
    return postPromiser(url,builder);
}

export const getBuilder = (builderId) => {
    const url = `${process.env.REACT_APP_API_URL}/builders/${builderId}`;
    return getPromiser(url);
}

export const createImageLabel = (label) => {
    var url = `${process.env.REACT_APP_API_URL}/imagelabel`;
    return postPromiser(url,label);
}

export const patchImage = (imageId, newImage) => {
    var url = `${process.env.REACT_APP_API_URL}/images/events/${imageId}`;
    return patchPromiser(url,newImage);
}

export const patchImageLabel = (imageLabelId, newImageLabel) => {
    var url = `${process.env.REACT_APP_API_URL}/imagelabel/${imageLabelId}`;
    return patchPromiser(url,newImageLabel);
}

export const getSharedCaps = (userId) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/${userId}/sharedCaps`;
    return getPromiser(url);
}

export const getCapsByUserId = (userId) => {
    const filter = '?filter[where][status][inq]=ACTIVE&filter[where][status][inq]=BUILD';
    const url = `${process.env.REACT_APP_API_URL}/caps/user/${userId}${filter}`;
    return getPromiser(url);
}

export const getUserMotions = (userId) => {
    const url = `${process.env.REACT_APP_API_URL}/userMotion/user/${userId}`;
    return getPromiser(url);
}

export const delSharing = (userCapId) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/${userCapId}`;
    return delPromiser(url);
}

export const resetPasswordInit = (email) => {
    const url = `${process.env.REACT_APP_API_URL}/resetPassword/init`;
    return postPromiser(url,{email:email});
}

export const resetPasswordFinish = (reset) => {
    const url = `${process.env.REACT_APP_API_URL}/resetPassword/finish`;
    return postPromiser(url,reset);
}

export const postStripeCustomer = (reservation) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/stripe/createAccount`;
    return postPromiser(url,reservation);
}

export const postStripePayment = (reservation) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/stripe/createPaymentIntent`;
    return postPromiser(url,reservation);
}

export const cancelStripePayment = (paymentIntent) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/stripe/cancelPaymentIntent`;
    return postPromiser(url,paymentIntent);
}

export const getStripePayment = (paymentIntent) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/stripe/paymentIntent/${paymentIntent.id}`;
    return getPromiser(url);
}

export const resetStripeReader = (terminalID) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/stripe/resetReader`;
    return postPromiser(url,terminalID);
}

export const patchSharing = (userCapId,userCap) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/${userCapId}`;
    return patchPromiser(url,userCap);
}

export const patchUserNotification = (userNotificationId, update) => {
    const url = `${process.env.REACT_APP_API_URL}/userNotification/${userNotificationId}`;
    return patchPromiser(url,update);
}

export const postUnsubscribe = (userId) => {
    const url = `${process.env.REACT_APP_API_URL}/users/${userId}/unsubscribe`;
    return postPromiser(url,{});
}

export const createCCIntegration = (userId,bearer) => {
    const url = `${process.env.REACT_APP_API_URL}/userintegrations`;
    const newCCIntegration = {
        userId:userId,
        name:"CompanyCam",
        integration:bearer
    }
    return postPromiser(url,newCCIntegration);
}

export const getUserIntegrations = (userId) => {
    const url = `${process.env.REACT_APP_API_URL}/userintegrations/user/${userId}`;
    return getPromiser(url);
}

export const addCCCapProject = (userIntegration,newCap,newProject,monitors) => {
    const url = `${process.env.REACT_APP_API_URL}/userintegrations/${userIntegration.userIntegrationId}`;
    if(userIntegration.integration.cap_projects==null) {
        userIntegration.integration.cap_projects = {};
    }
    userIntegration.integration.cap_projects[newCap]={project_id:newProject,monitors:monitors};
    return patchPromiser(url,{integration:userIntegration.integration});
}

export const removeCCCapProject = (userIntegration,oldCap) => {
    const url = `${process.env.REACT_APP_API_URL}/userintegrations/${userIntegration.userIntegrationId}`;
    delete userIntegration.integration.cap_projects[oldCap];
    return patchPromiser(url,{integration:userIntegration.integration});
}

export const postShareWithEmail = (capId,email,accessType,power) => {
    const url = `${process.env.REACT_APP_API_URL}/userCaps/shareByEmail`;
    const data = {
        'email': email,
        'capId': capId,
        'accessType': accessType,
        'power':power
    }
    return postPromiser(url,data);
}

export const patchUser = (userId,changes,token="") => {
    const url = `${process.env.REACT_APP_API_URL}/users/${userId}`;
    return patchPromiser(url,changes,token);
}

export const patchUserTZ = (userId,tzOffset) => {
    const url = `${process.env.REACT_APP_API_URL}/users/${userId}`;
    return patchPromiser(url,{tzOffset:tzOffset});
}

export const patchLightMode = (capId,lightMode) => {
    const url = `${process.env.REACT_APP_API_URL}/caps/${capId}/changeLightMode`;
    return patchPromiser(url,{'lights':lightMode});
}

export const postActivateAccount = (userId,hash,password) => {
    const url = `${process.env.REACT_APP_API_URL}/user/activateAccount`;
    const data = {
        userId:userId,
        password:hash,
        newPassword:password
    }
    const config = {
        headers: {
            'Content-Type':'application/json',
            'accept':'application/json'
        }
    }
    console.log(url,data,config);
    return new Promise((resolve,reject) => {
        axios.post(
            url,
            data,
            config
        ).then(response => {
            resolve(response.data.token);
        }).catch(e => {
            console.log(e);
            reject(e);
        });
    });
};

export const deleteEventVideo = (event) => {
    const url = `${process.env.REACT_APP_API_URL}/events/${event}`;
    const update = {'status':'DELETE'};
    return patchPromiser(url,update);
    // return new Promise((resolve,reject) => {
    //     axios.delete(
    //         `${process.env.REACT_APP_API_URL}/events/${event}`,
    //         config
    //     ).then(response => {
    //         resolve(response);
    //     }).catch(e => {
    //         console.log(e);
    //         reject(e);
    //     });
    // });
};

export const deleteCaptureVideo = (capture) => {
    const config = getConfig();
    return new Promise((resolve,reject) => {
        axios.delete(
            `${process.env.REACT_APP_API_URL}/captures/${capture}`,
            config
        ).then(response => {
            resolve(response);
        }).catch(e => {
            console.log(e);
            reject(e);
        });
    });
};

export const deleteFile = (cap,f) => {
    const config = getConfig();
    return new Promise((resolve, reject) => {
        axios.delete(
            `${process.env.REACT_APP_API_URL}/caps/${cap}/files/${f}`,
            config
          ).then(response => {
            // console.log(response.data);
            resolve(response);
          }).catch(error => {
            console.log(error);
            reject(error);
          });
    });
}

export const uploadFile = (cap,f,setProgress) => {
    const token = getToken();
    const headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        'Authorization':`Bearer ${token}`
    }
    let formData = new FormData();
    formData.append('file',f);
    const url = `${process.env.REACT_APP_API_URL}/caps/${cap}/newFile`;
    return new Promise((resolve,reject) => {
        axios({
            method:'post',
            url:url,
            data:formData,
            headers:headers,
            onUploadProgress: ProgressEvent => {
                setProgress(ProgressEvent.loaded/ProgressEvent.total*100);
            }
        }).then(response => {
            resolve(response.data);
        }).catch(e => {
            console.log(e);
            reject(e);
        });
    });
}

export const getImagesFromCapThroughCloud = (cap,monitors,camToken) => {
    const token = getToken();
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
    }
    var ids = [];
    // console.log(cap,monitors,camToken);
    for(var i=0; i<monitors.length; i++) {
        ids.push({'id':monitors[i].zmId.toString(),'monitorId':monitors[i].monitorId,'name':monitors[i].name})
    }
    const data = {
        "port": cap.port,   
        "width": monitors[0].width,
        "height": monitors[0].height,
        "ids": ids,
        "token": camToken,
    }
    const url = `${process.env.REACT_APP_API_URL}/caps/${cap.capId}/live`
    return new Promise((resolve,reject) => {
        axios.post(url,data,config).then(response => {
            if(response.data.statusCode===500) {
                // console.log(response.data);
                reject("Can't reach the cap")
            }
            // console.log(response.data.body);
            resolve(response.data.body);
        }).catch(e => {
            // console.log(e);
            reject(e);
        });
    });
};

export const recordUserAction = (userId,description,capId=null,meta=null) => {
    const token = getToken();
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
    };
    const data = {
        'description': description,
        'actionDate': new Date()
    }
    if(capId) data['capId'] = capId;
    if(meta) data['meta'] = meta;
    const url = `${process.env.REACT_APP_API_URL}/userAction/${userId}/newAction`;
    return new Promise((resolve,reject) => {
        axios.post(
            url,data,config
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
};

// TODO this should really be in the API
export const updateUserSubscription = async (userId,subscription,subscribe='Yes',desktop=false) => {
    let newUser;
    let oldUser;
    try {
        oldUser = await getUserMe();
    } catch(e) {
        try {
            oldUser = await getUser();
        } catch(e) {
            console.log('Cannot get current user, not logging fcm token');
            return null;
        }
    }
    if(!oldUser) {
        return;
    }
    if(subscribe) {
        // DB has notifications objects
        if(oldUser.settings && ((desktop && oldUser.settings.desktopNotifications) || (!desktop && oldUser.settings.appNotifications))) {
            // OLD BAD FORMAT REWRITE
            if(oldUser.settings.appNotifications && oldUser.settings.appNotifications.fcmToken) {
                oldUser.settings.appNotifications=[oldUser.settings.appNotifications];
            }
            if(oldUser.settings.desktopNotifications && oldUser.settings.desktopNotifications.fcmToken) {
                oldUser.settings.desktopNotifications=[oldUser.settings.desktopNotifications];
            }
            let matches = false;
            if(desktop) {
                // Match desktop token (do nothing)
                for(var i=0; i<oldUser.settings.desktopNotifications.length; i++) {
                    if(oldUser.settings.desktopNotifications[i].fcmToken===subscription.fcmToken) {
                        matches = true;
                        break;
                    }
                }
            } else {
                // Match mobile token (do nothing)
                for(var i=0; i<oldUser.settings.appNotifications.length; i++) {
                    if(oldUser.settings.appNotifications[i].fcmToken===subscription.fcmToken) {
                        matches = true;
                        break;
                    }
                }
            }
            if(!matches) { // else, do nothing because token exists
                // Add token to repsective because new
                if(desktop) {
                    oldUser.settings.desktopNotifications.push(subscription);
                } else {
                    oldUser.settings.appNotifications.push(subscription);
                }
            }
            // apply updated notifications object
            if(desktop) {
                newUser = {settings:{desktopNotifications:oldUser.settings.desktopNotifications}};
            } else {
                newUser = {settings:{appNotifications:oldUser.settings.appNotifications}};
            }
        } else { // notifications object is new
            if(desktop) {
                newUser = {settings:{desktopNotifications:[subscription]}};
            } else {
                newUser = {settings:{appNotifications:[subscription]}};
            }
        } // over-override for respective
        if(desktop) {
            newUser.settings.desktopEnabled = true;
        } else {
            newUser.settings.appEnabled = true;
        }
    } else {
        // notifications objects exist
        if(oldUser.settings && ((desktop && oldUser.settings.desktopNotifications) || (!desktop && oldUser.settings.appNotifications))) {
            let tokenIndex = -1;
            if(desktop) {
                // find existing token desktop
                for(var i=0;i<oldUser.settings.desktopNotifications.length; i++) {
                    if(oldUser.settings.desktopNotifications[i].fcmToken===subscription.fcmToken) {
                        tokenIndex = i;
                    }
                }
            } else {
                // find existing token mobile
                for(var i=0; i<oldUser.settings.appNotifications.length; i++) {
                    if(oldUser.settings.appNotifications[i].fcmToken===subscription.fcmToken) {
                        tokenIndex = i;
                    }
                }
            }
            // Remove token if exists
            if(tokenIndex>-1) {
                if(desktop) {
                    oldUser.settings.desktopNotifications.splice(tokenIndex,1);
                } else {
                    oldUser.settings.appNotifications.splice(tokenIndex,1);
                }
            }
            // objects should be null not []
            if(oldUser.settings.desktopNotifications.lenth===0) {
                oldUser.settings.desktopNotifications = null;
            }
            if(oldUser.settings.appNotifications.length===0) {
                oldUser.settings.appNotifications = null;
            }
        } else { // objects don't exist, but should as null
            if(desktop) {
                oldUser.settings.desktopNotifications = null;
            } else {
                oldUser.settings.appNotifications = null;
            }
        }
        // update the notifications object and the over-override toggle
        if(desktop) {
            newUser = {settings:{desktopEnabled:false,desktopNotifications:oldUser.settings.desktopNotifications}};
        } else {
            newUser = {settings:{appEnabled:false,appNotifications:oldUser.settings.appNotifications}};
        }
    }
    patchUser(userId,newUser);
}

export const updateUserPWA = (userId,pwa=false) => {
    const newUser = {settings:{pwaInstalled:pwa}}
    patchUser(userId,newUser);
}