import React, {useState, useRef, useEffect} from "react";
import { Stage, Layer, Line, Image, Circle, Ring } from "react-konva";
// import Konva from "konva";
import useImage from "use-image";
import { chunk } from "../utils/Common";

const MARGIN = 12;
const DEFAULT_OPACITY = 0.4;
var imageWidth = 352;

export default function ZoneEditor({zone,url,id,w,h,type,onChange=null,...props}) {
    const DEFAULT_ANCHORS = [MARGIN,MARGIN,MARGIN,h+MARGIN,w+MARGIN,h+MARGIN,w+MARGIN,MARGIN];

    const anchorRef = useRef();
    const polygonRef = useRef();
    const midRef = useRef();
    const [points, setPoints] = useState(zone ? zone : DEFAULT_ANCHORS);
    const [midPoints, setMidPoints] = useState(null);
    const [color] = useState("#A5FF7F");
    const [image, status] = useImage(url);
    const [radii, setRadii] = useState(null);
    const [dragger, setDragger] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(points && onChange) {
            onChange(id,undoZone(points,type));
        }
    },[points]);

    function transformZone(z,t="low") {
        var newZone = [];
        for(var i=0; i<z.length; i++) {
            var n = Math.floor(z[i]*w/imageWidth)+MARGIN;
            if(i%2===0) {
                if(n>=w-6+MARGIN) {
                    n=w+MARGIN;
                }
            } else {
                if(n>=h-6+MARGIN) {
                    n=h+MARGIN;
                }
            }
            newZone.push(n);
        }
        return newZone;
    }

    function undoZone(z,t="low") {
        var newZone = [];
        for(var i=0; i<z.length; i++) {
            var n = Math.floor((z[i]-MARGIN)*imageWidth/w);
            if(i%2===0) {
                if(n>imageWidth-4) {
                    n=imageWidth-4;
                }
            } else {
                if(n>(imageWidth*h/w)-4) {
                    n=(imageWidth*h/w)-4;
                }
            }
            newZone.push(n);
        }
        return newZone;
    }

    if(isLoading) {
        if(type==="medium") {
            imageWidth = 704;
        } else {
            imageWidth = 352;
        }
        setPoints(transformZone(zone,type));
        setIsLoading(false);
    }

    const calculateMidPoints = function(corners) {
        var mids = [];
        for(var i=0; i<corners.length; i+=2) {
            // This handles loop around between points n and 0
            const j = i > corners.length-3 ? i-corners.length : i;
            // This checks that there's enough space to add the midpoint
            // TODO: This causes a bug where new points added after sections without midpoints
            // don't end up in the correct spot -- not critical
            const delta = (Math.abs(corners[j+2]-corners[i])+Math.abs(corners[j+3]-corners[i+1]));
            if(delta > 60) {
                const x = Math.floor((corners[j+2]+corners[i])/2);
                const y = Math.floor((corners[j+3]+corners[i+1])/2);
                mids.push(x);
                mids.push(y);
            }
        }
        setMidPoints(mids);
    }

    useEffect(() => {
        if(points) {
            calculateMidPoints(points);
            var midRadii = [];
            var draggers = [];
            for(var i=0; i<points.length; i++) {
                midRadii.push(MARGIN-3);
                draggers.push(DEFAULT_OPACITY);
            }
            setRadii(midRadii);
            setDragger(draggers);
        }
    }, [points]);

    const bounds = function(pos,max) {
        return pos > MARGIN && pos < max+MARGIN ? pos : pos > MARGIN ? max+MARGIN : MARGIN;
    }

    return (
        <Stage width={w+(2*MARGIN)} height={h+(2*MARGIN)} >
            {status==="loaded" && (
                <Layer>
                    <Image image={image} width={w} height={h} x={MARGIN} y={MARGIN} />
                </Layer>
            )}
            {!isLoading && (
                <Layer>
                    <Line
                        closed
                        fill={color}
                        opacity={DEFAULT_OPACITY}
                        ref={polygonRef}
                        points={points}
                    />
                    {chunk(midPoints,2).map((mid,i) => (
                        <Ring
                            key={i}
                            ref={midRef}
                            x={mid[0]}
                            y={mid[1]}
                            outerRadius={MARGIN-1}
                            innerRadius={radii[i]}
                            stroke={radii[i]===0 ? "black" : null}
                            fill={color}
                            opacity={0.7}
                            onMouseEnter={() => {
                                var temp = [...radii];
                                temp[i] = 0;
                                setRadii(temp);
                            }}
                            onMouseLeave={() => {
                                var temp = [...radii];
                                temp[i] = MARGIN-3;
                                setRadii(temp);
                            }}
                            onMouseDown={() => {
                                var newPoints = [...points];
                                newPoints.splice(2*i+2,0,mid[0]);
                                newPoints.splice(2*i+3,0,mid[1]);
                                setPoints(newPoints);
                                calculateMidPoints(newPoints);
                                // TODO -- Add point to points
                            }}
                        />
                    ))}
                    {dragger && chunk(points,2).map((anchor,i) => (
                        <Circle
                            key={i}
                            ref={anchorRef}
                            x={anchor[0]}
                            y={anchor[1]}
                            radius={MARGIN-1}
                            stroke="black"
                            fill={color}
                            opacity={dragger[i]}  
                            draggable
                            dragBoundFunc={(pos) => {
                                return {
                                    x: bounds(pos.x,w),
                                    y: bounds(pos.y,h)
                                };
                            }}
                            onDragMove={(e) => {
                                var newPoints = [...points];
                                newPoints[i*2] = bounds(e.target.x(),w);
                                newPoints[i*2+1] = bounds(e.target.y(),h);
                                setPoints(newPoints);
                                calculateMidPoints(newPoints);
                                var temp = [...dragger];
                                temp[i] = 1.0;
                                setDragger(temp);
                            }}
                            onMouseEnter={() => {
                                var temp = [...dragger];
                                temp[i] = 1.0;
                                setDragger(temp);
                            }}
                            onMouseLeave={() => {
                                var temp = [...dragger];
                                temp[i] = DEFAULT_OPACITY;
                                setDragger(temp);
                            }}
                            onDblClick={() => {
                                var tempPoints = [...points];
                                tempPoints.splice(i*2,1);
                                tempPoints.splice(i*2,1);
                                setPoints(tempPoints);
                            }}
                        />
                    ))}
                    {/* {props.children} */}
                </Layer>
            )}
        </Stage>
    )
}