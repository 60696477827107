import React, {useState, useEffect} from "react";
import { View } from "react-native";
import { Button, Glyphicon, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Player, BigPlayButton, ControlBar, VolumeMenuButton } from "video-react";
import { getEvent, getCapture, getEventVideo, getEventImage, deleteEventVideo } from "../utils/bedrockAPI";
import "video-react/dist/video-react.css"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./MotionEvent.css"

export default function MotionEvent({eventId,reloadEvents,props}) {
    const [event, setEvent] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    // const [capture, setCapture] = useState(null);
    const [badEvent, setBadEvent] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");
    const [showCapture, setShowCapture] = useState(false);
    const [eventImage, setEventImage] = useState(null);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[])

    useEffect(() => {
        let isMounted = true;
        async function loadEvent() {
            try {
                const tempEvent = await getEvent(eventId);
                if(isMounted) {
                    setEvent(tempEvent);
                }
            } catch(e) {
                if(isMounted) setBadEvent(true);
            }
        }
        if(eventId) {
            loadEvent();
        }
        return () => { isMounted = false; };
    },[eventId]);

    useEffect(() => {
        let isMounted = true;
        // async function loadCapture() {
        //     try {
        //         const tempCapture = await getCapture(event.captureId);
        //         if(isMounted) setCapture(tempCapture);
        //     } catch(e) {
        //         // pass
        //     }
        // }
        async function loadVideoUrl() {
            try {
                const tempVideoUrl = await getEventVideo(event.monitorId,event.eventId);
                if(isMounted) setVideoUrl(tempVideoUrl);
            } catch(e) {
                if(isMounted) setBadEvent(true);
            }
        }
        async function loadEventImage() {
            try {
                const tempEventImage = await getEventImage(event.eventId);
                if(isMounted) setEventImage(tempEventImage);
            } catch(e) {
                // pass - Event may not have capture
            }
        }
        if(event) {
            loadVideoUrl();
        }
        if(event && event.captureId) {
            // loadCapture();
            loadEventImage();
        }
        return () => { isMounted = false; };
    },[event]);

    const snapshotTooltip = (
        <Tooltip id="snapshot" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
            {showCapture ? "Switch to Motion Video" : "Switch to High Resolution Snapshot"}
        </Tooltip>
    );

    const downloadTooltip = (
        <Tooltip id="download" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
            Right Click -> Save-As to Download the File
        </Tooltip>
    );

    const deleteTooltip = (
        <Tooltip id="delete" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
            Delete the Motion Event and Snapshot
        </Tooltip>
    );

    async function removeEvent() {
        if(window.confirm(`Are you sure you wish to delete this event?`)) {
            await deleteEventVideo(event.eventId);
            reloadEvents();
        }    
    }

    return (
        <div>
            {event && videoUrl && !badEvent ? (
                <View style={{flex:1,flexDirection:"column",justifyContent:"center"}}>
                    <View style={{flex:1,flexDirection:"row",justifyContent:"center",paddingBottom:"10px"}}>
                        <View style={{paddingRight:"5px",width:"50%",maxWidth:"352px"}}>
                            <OverlayTrigger placement="left" overlay={snapshotTooltip}>
                                <Button
                                    bsStyle="warning"
                                    bsSize={buttonSize}
                                    onClick={() => setShowCapture(!showCapture)}
                                    disabled={!eventImage || !eventImage.url}
                                >
                                    {/* {showCapture ? <Glyphicon glyph="film" /> : <Glyphicon glyph="picture" />} */}
                                    {!showCapture ? "HD Image" : "Motion Video" }
                                </Button>
                            </OverlayTrigger>
                        </View>
                        {/* <OverlayTrigger placement="top" overlay={deleteTooltip}>
                            <View style={{paddingRight:"5px"}}>
                                <Button
                                    bsStyle="danger"
                                    bsSize={buttonSize}
                                    onClick={() => removeEvent()}
                                >
                                    <Glyphicon glyph="remove" />
                                </Button>
                            </View>
                        </OverlayTrigger> */}
                        <View style={{width:"50%", paddingLeft:"5px", maxWidth:"352px"}}>
                            <OverlayTrigger placement="right" overlay={downloadTooltip}>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    href={(showCapture && eventImage && eventImage.url) ? eventImage.url : videoUrl.url}
                                >
                                    {/* <Glyphicon glyph="download-alt" /> */}
                                    Download
                                </Button>
                            </OverlayTrigger>
                        </View>
                    </View>
                    {showCapture && eventImage && eventImage.url ? (
                        <View style={{flex:1,flexDirection:"row",justifyContent:"center"}}>
                            <TransformWrapper>
                                {({scale,...rest}) => (
                                    <View style={{width:"100%",maxWidth:"2688px",height:undefined,aspectRatio:1.7684}}>
                                        <TransformComponent>
                                            <img src={eventImage.url} alt="" />
                                        </TransformComponent>
                                    </View>
                                )}
                            </TransformWrapper>
                        </View>
                    ) : (
                        <View style={{flex:1,flexDirection:"row",justifyContent:"center"}}>
                            <View style={{width:"100%",maxWidth:"704px",height:undefined,aspectRatio:1.4667}}>
                                <Player>
                                    <source src={videoUrl.url} />
                                    <BigPlayButton position="center" />
                                    <ControlBar>
                                        <VolumeMenuButton disabled />
                                    </ControlBar>
                                </Player>
                            </View>
                        </View>
                    )}
                </View>
            ) : (
                <div>
                    Unable to get motion event video, try again in a little bit
                </div>
            )}
        </div>
    )
}